function tokensExists() {
  return (
    localStorage.getItem("access_token") &&
    localStorage.getItem("refresh_token")
  );
}

function authorizeWhenTokensExist() {
  return new Promise((resolve, reject) => {
    if (tokensExists()) {
      resolve({
        onInit: true,
      });
      return;
    }

    let interval = setInterval(() => {
      if (tokensExists()) {
        clearInterval(interval);
        resolve({
          onInit: false,
        });
      }
    }, 1000);
  });
}

function callbackWithTokens() {
  let route = useRoute();
  let accessToken = route.query?.access_token ?? "";
  let refreshToken = route?.query.refresh_token ?? "";
  let sessionToken = route?.query.session_token ?? "";

  return {
    accessToken,
    refreshToken,
    sessionToken,
    hasTokens: accessToken && refreshToken,
  };
}

function isGuestDomain() {
  const publicConfig = useRuntimeConfig().public;
  const currentDomain = window.location.origin;
  return publicConfig.GUEST_DOMAINS.includes(currentDomain);
}

export function initGateway() {
  let tokens = callbackWithTokens();

  if (tokens.hasTokens) {
    setTokens(tokens.accessToken, tokens.refreshToken, tokens.sessionToken);
  }

  if (useRoute().query?.next_step === "clear-tokens") {
    clearTokens();
    return;
  }

  authorizeWhenTokensExist().then(() => {
    useDefaultStore()
      .loadUser()
      .then(() => {
        if (isGuestDomain()) {
          const publicConfig = useRuntimeConfig().public;
          const redirectUrl =
            publicConfig.CORE_SPA_DOMAIN +
            "/?access_token=" +
            localStorage.getItem("access_token") +
            "&refresh_token=" +
            localStorage.getItem("refresh_token") +
            "&session_token=" +
            localStorage.getItem("session_token");
          window.location.href = redirectUrl;
        } else {
          console.info("No need to redirect");
        }
      });
  });
}
